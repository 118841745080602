
@font-face {
  font-family: 'Source Code Pro';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  src: url("./fonts/source-code/SourceCodePro-Regular.eot");
  src: url("./fonts/source-code/SourceCodePro-Regular.eot?#iefix") format('embedded-opentype'), url("./fonts/source-code/SourceCodePro-Regular.ttf.woff2") format('woff2'), url("./fonts/source-code/SourceCodePro-Regular.otf.woff") format('woff'), url("./fonts/source-code/SourceCodePro-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Source Code Pro';
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  src: url("./fonts/source-code/SourceCodePro-Bold.eot");
  src: url("./fonts/source-code/SourceCodePro-Bold.eot?#iefix") format('embedded-opentype'), url("./fonts/source-code/SourceCodePro-Bold.ttf.woff2") format('woff2'), url("./fonts/source-code/SourceCodePro-Bold.otf.woff") format('woff'), url("./fonts/source-code/SourceCodePro-Bold.ttf") format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/source-sans/SourceSansPro-Regular.eot");
  src: url("./fonts/source-sans/SourceSansPro-Regular.eot?#iefix") format('embedded-opentype'), url("./fonts/source-sans/SourceSansPro-Regular.woff2") format('woff2'), url("./fonts/source-sans/SourceSansPro-Regular.woff") format('woff'), url("./fonts/source-sans/SourceSansPro-Regular.ttf") format('truetype'), url("./fonts/source-sans/SourceSansPro-Regular.svg#SourceSansPro-Regular") format('svg');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: normal;
  src: url("./fonts/source-sans/SourceSansPro-It.eot");
  src: url("./fonts/source-sans/SourceSansPro-It.woff2") format('woff2'), url("./fonts/source-sans/SourceSansPro-It.woff") format('woff'), url("./fonts/source-sans/SourceSansPro-It.ttf") format('truetype'), url("./fonts/source-sans/SourceSansPro-It.svg#SourceSansPro-It") format('svg');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/source-sans/SourceSansPro-SemiBold.eot");
  src: url("./fonts/source-sans/SourceSansPro-SemiBold.woff2") format('woff2'), url("./fonts/source-sans/SourceSansPro-SemiBold.woff") format('woff'), url("./fonts/source-sans/SourceSansPro-SemiBold.ttf") format('truetype'), url("./fonts/source-sans/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold") format('svg');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: bold;
  src: url("./fonts/source-sans/SourceSansPro-SemiBoldIt.eot");
  src: url("./fonts/source-sans/SourceSansPro-SemiBoldIt.woff2") format('woff2'), url("./fonts/source-sans/SourceSansPro-SemiBoldIt.woff") format('woff'), url("./fonts/source-sans/SourceSansPro-SemiBoldIt.ttf") format('truetype'), url("./fonts/source-sans/SourceSansPro-SemiBoldIt.svg#SourceSansPro-SemiBoldIt") format('svg');
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 17px;
  color: #253746;
  background: #f8f9fb;
  letter-spacing: 0;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: normal;
}

.header {
    height: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #253746;
}

.logo {
  color: #e8503e;
  width: 100%;
  height: 45px;
  float: left;
  background: url(./img/logo.png);
  background-repeat: no-repeat;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  background-size: auto 100%;
  padding: 0;
  font-size: 1.5em;
  font-weight: 700;
  text-decoration: none;
}

.swagger-section {
	padding-bottom: 100px;
}

.swagger-section .swagger-ui-wrap {
  line-height: 1;
  font-family: Droid Sans, sans-serif;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: normal;
}

.swagger-section .swagger-ui-wrap .swagger-ui {
	font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: normal;
}

.swagger-section .swagger-ui-wrap p {
  line-height: 1.4em;
  padding: 0 0 5px;
  margin: 0;
  color: #333;
}

.swagger-section .swagger-ui .markdown code, .swagger-section .swagger-ui .renderedMarkdown code {
    font-size: 14px;
    padding: 5px 7px;
    border-radius: 4px;
    background: rgba(0,0,0,.05);
    font-family: monospace;
    font-weight: 600;
    color: #e8503e;
}

/* From previous version */

.swagger-section .swagger-ui-wrap a {
  color: #41607b;
  text-decoration: none;
  -webkit-transition: color 150ms, background 150ms;
  -moz-transition: color 150ms, background 150ms;
  -o-transition: color 150ms, background 150ms;
  -ms-transition: color 150ms, background 150ms;
  transition: color 150ms, background 150ms;
}
.swagger-section .swagger-ui-wrap a:hover,
.swagger-section .swagger-ui-wrap a:focus {
  color: #e8503e;
  text-decoration: none;
}
.swagger-section .swagger-ui-wrap a.disabled {
  color: #a5b0b8;
  cursor: default;
}

.footer {
	height: 100px;
}

.swagger-section .swagger-ui-wrap .title small {
	display: none;
}

.swagger-section .swagger-ui-wrap input::placeholder {
  color: rgba(255, 255, 255, 0);
}

.swagger-section .swagger-ui-wrap input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0);
}

.swagger-section .swagger-ui-wrap input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0);
}
